/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDd0zFD5mbFpuQUE8e1tRWpTgQsXEfqpKw",
  "appId": "1:932707185824:web:905e5553c59fea7e5745c5",
  "authDomain": "schooldog-i-dooly-ga.firebaseapp.com",
  "measurementId": "G-9EZTKXZNLL",
  "messagingSenderId": "932707185824",
  "project": "schooldog-i-dooly-ga",
  "projectId": "schooldog-i-dooly-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dooly-ga.appspot.com"
}
